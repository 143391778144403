import React, { useEffect } from "react"
import QueryString from "query-string"
import PageRoot from "../../components/PageRoot"
import { getImageUrl, pushPurchaseEvent, translatePath } from "../../utils"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { Link } from "gatsby"
import { Button, Card, Divider, Skeleton } from "antd"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../../constants/imageSizes"
import { GET_PURCHASE_QUERY } from "../../constants/queries"

const CheckoutCompletePage = ({ location }) => {
  const { t } = useTranslation()

  const params = QueryString.parse(location.search)
  const { id } = params
  const { loading, data } = useQuery(GET_PURCHASE_QUERY, {
    variables: { id },
  })

  const { item_type, item_id } = data?.user_purchases_by_pk || {}

  const item = data?.user_purchases_by_pk[item_type]

  const {
    title,
    short_description,
    price,
    landscape,
    landscape_locked,
    landscape_success,
    criterion,
  } = item || {}

  const coverUrl = getImageUrl(
    landscape_locked || landscape_success || landscape || criterion?.picture,
    DEFAULT_LANDSCAPE_IMAGE_SIZE
  )

  useEffect(() => {
    pushPurchaseEvent({
      price,
      itemId: item_id,
      transactionId: id,
      name: title,
    })
  }, [])

  return (
    <PageRoot title={t("label:paymentSuccess")} isDark={false}>
      <div className="checkout-form-wrapper">
        <ContainerWithPadding size="small" align="center">
          {loading || !item ? (
            <Skeleton active />
          ) : (
            <div className="purchase-preview-wrapper">
              <Card
                style={{ width: "100%", marginBottom: 50 }}
                cover={<img alt={title} src={coverUrl} />}
                bordered={false}
              >
                <Card.Meta title={title} description={short_description} />
              </Card>
            </div>
          )}
          <h2>{t("message:purchaseCompleted")}</h2>
          <p>{t("message:purchaseCompletedMessage1")}</p>
          <Button className="yellowsp" type="primary">
            <Link to={translatePath("/me/library")}>
              {t("button:goToMyLibrary")}
            </Link>
          </Button>
          <br />
          <br />
          <Divider>{t("message:or")}</Divider>
          <p>
            <Link className="home-link" to="/">
              {t("button:goMembership")}
            </Link>
          </p>
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export default CheckoutCompletePage
